<template>
  <div id="login-wrapper">
    <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
    <div class="login-box">
      <div class="flex justify-center">
        <el-tabs v-model="activeName" class=" bs shrink_0 login-tabs">
          <el-tab-pane label="注册" name="first">
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-show="activeName == 'first'">
        <el-form ref="ruleFormRef" :model="loginRuleFrom" status-icon :rules="loginrules" class="demo-ruleForm"
          hide-required-asterisk style="margin-top:16px;">
          <el-form-item label="" prop="phone" show-message :error="phoneErr">
            <el-input v-model="loginRuleFrom.phone" type="text" placeholder="请输入您的手机号" />
          </el-form-item>
          <el-form-item label="" prop="phonecode" :error="phoneCodeErr">
            <el-input v-model="loginRuleFrom.phonecode" type="password" placeholder="请输入短信验证码">
              <template #append>
                <div class="login_code" @click="getCode">{{ isDisposed ? `${time}s后重新获取` : "获取验证码" }}</div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="w100 flex flex-direction align-center" style="padding-top: 22px;">
              <el-button type="primary" @click="submitForm(ruleFormRef)" style="width:70%;">注册</el-button>
              <div class="mt_20">
                已有账号? <span style="color: #436ef3;" class="cur-p" @click="goLogin">去登录</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref, getCurrentInstance } from "vue";
import { Store } from "vuex";
import store from "@/store/index";
import router from "@/router/index";
import axios from "axios";
import { ElLoading, ElMessage } from 'element-plus'
import { apiLogin, apiSendCode } from '@/util/apis'
export default defineComponent({
  name: "",
  setup: () => {
    var loading: { close: () => void; } | null = null;
    const $store: Store<any> = store;
    const login = () => {
      $store.dispatch("GET_ROUTERS_DATA");
    };
    const state = reactive({
      loginRuleFrom: {
        phone: '',
        phonecode: ''
      },
      loginrules: {
        phone: [{ required: true, message: '手机号未填写', trigger: 'blur' }, {
          trigger: 'blur',
          validator: (rule: any, value: any, callback: any) => {
            var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
            !passwordreg.test(value) ? callback(new Error('请输入正确的手机号码哦!')) : callback();
          }
        }],
        phonecode: [{ required: true, message: '短信验证码未填写', trigger: 'blur' }]
      },
      activeName: 'first',
      isDisposed: false,
      time: 60,
      phoneErr: '',
      phoneCodeErr: ''
    });
    const ruleFormRef = ref(null);
    const submitForm = async (formEl: any) => {
      if (!formEl) return
      await formEl.validate((valid: any, fields: any) => {
        if (valid) {
          loading =
            ElLoading.service({
              lock: true,
              text: 'Loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
          apiLogin({
            mode: 'phone',
            mobile: state.loginRuleFrom.phone,
            checkCode: state.loginRuleFrom.phonecode
          }).then(res => {
            console.log('res==', res)
            if (res.code == 0 && res.data.token) {
              $store.dispatch("GET_ROUTERS_DATA");
              localStorage.setItem(
                "v3-element-plus-token",
                JSON.stringify(res.data.token)
              );
            } else if (res.code == 90000 && res.msg) {
              ElMessage.error(res.msg)
            }
            loading!.close()
          }).catch(f => {
            console.log('f==', f)
            loading!.close()
          })
        }
      })
    }
    const resetForm = (formEl: any) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const getCode = () => {
      if (!state.loginRuleFrom.phone) {
        state.phoneErr = '手机号未填写';
        return
      }
      var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
      if (!passwordreg.test(state.loginRuleFrom.phone)) {
        state.phoneErr = '请输入正确的手机号码哦!';
        return
      }
      if (!state.isDisposed) {
        state.isDisposed = true;
        apiSendCode({
          mobile: state.loginRuleFrom.phone
        }).then(res => {
          handleTimeChange();
          ElMessage({
            showClose: true,
            message: '发送成功',
            type: 'success',
          })
        }).catch(f => {
          state.isDisposed = false;
        })

      }
    }
    const handleTimeChange = () => {
      if (state.time <= 0) {
        state.isDisposed = false;
        state.time = 60;
      } else {
        setTimeout(() => {
          state.time--;
          handleTimeChange();
        }, 1000);
      }
    };

    const goLogin = () => {
      router.push({ path: "/login" });
    }
    return {
      login,
      ...toRefs(state), submitForm, resetForm, ruleFormRef, getCode, goLogin
    };
  },
});
</script>
<style scoped>
@import "./index.css";


#login-wrapper {
  /* background: linear-gradient(#141e30, #243b55); */
  background: rgb(67, 110, 243);
  /* background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%); */
  height: 100%;
  overflow: hidden;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 466px;
  padding: 40px;
  min-height: 450px;
  transform: translate(-50%, -50%);
  /* background: rgba(0, 0, 0, 0.8); */
  box-sizing: border-box;
  /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6); */
  border-radius: 10px;

  background: #fff;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #2e395a;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  /* background: transparent; */
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.login-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}
</style>
