
import { defineComponent, reactive, toRefs, ref, getCurrentInstance } from "vue";
import { Store } from "vuex";
import store from "@/store/index";
import router from "@/router/index";
import axios from "axios";
import { ElLoading, ElMessage } from 'element-plus'
import { apiLogin, apiSendCode } from '@/util/apis'
export default defineComponent({
  name: "",
  setup: () => {
    var loading: { close: () => void; } | null = null;
    const $store: Store<any> = store;
    const login = () => {
      $store.dispatch("GET_ROUTERS_DATA");
    };
    const state = reactive({
      loginRuleFrom: {
        phone: '',
        phonecode: ''
      },
      loginrules: {
        phone: [{ required: true, message: '手机号未填写', trigger: 'blur' }, {
          trigger: 'blur',
          validator: (rule: any, value: any, callback: any) => {
            var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
            !passwordreg.test(value) ? callback(new Error('请输入正确的手机号码哦!')) : callback();
          }
        }],
        phonecode: [{ required: true, message: '短信验证码未填写', trigger: 'blur' }]
      },
      activeName: 'first',
      isDisposed: false,
      time: 60,
      phoneErr: '',
      phoneCodeErr: ''
    });
    const ruleFormRef = ref(null);
    const submitForm = async (formEl: any) => {
      if (!formEl) return
      await formEl.validate((valid: any, fields: any) => {
        if (valid) {
          loading =
            ElLoading.service({
              lock: true,
              text: 'Loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
          apiLogin({
            mode: 'phone',
            mobile: state.loginRuleFrom.phone,
            checkCode: state.loginRuleFrom.phonecode
          }).then(res => {
            console.log('res==', res)
            if (res.code == 0 && res.data.token) {
              $store.dispatch("GET_ROUTERS_DATA");
              localStorage.setItem(
                "v3-element-plus-token",
                JSON.stringify(res.data.token)
              );
            } else if (res.code == 90000 && res.msg) {
              ElMessage.error(res.msg)
            }
            loading!.close()
          }).catch(f => {
            console.log('f==', f)
            loading!.close()
          })
        }
      })
    }
    const resetForm = (formEl: any) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const getCode = () => {
      if (!state.loginRuleFrom.phone) {
        state.phoneErr = '手机号未填写';
        return
      }
      var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
      if (!passwordreg.test(state.loginRuleFrom.phone)) {
        state.phoneErr = '请输入正确的手机号码哦!';
        return
      }
      if (!state.isDisposed) {
        state.isDisposed = true;
        apiSendCode({
          mobile: state.loginRuleFrom.phone
        }).then(res => {
          handleTimeChange();
          ElMessage({
            showClose: true,
            message: '发送成功',
            type: 'success',
          })
        }).catch(f => {
          state.isDisposed = false;
        })

      }
    }
    const handleTimeChange = () => {
      if (state.time <= 0) {
        state.isDisposed = false;
        state.time = 60;
      } else {
        setTimeout(() => {
          state.time--;
          handleTimeChange();
        }, 1000);
      }
    };

    const goLogin = () => {
      router.push({ path: "/login" });
    }
    return {
      login,
      ...toRefs(state), submitForm, resetForm, ruleFormRef, getCode, goLogin
    };
  },
});
